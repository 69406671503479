import React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import HeroHeadline from "../components/heroheadline"
import Sectionheader from "../components/sectionheader"
import * as hero from '../styles/hero.module.css'
import * as styles from '../styles/innovations.module.css'

export default function Innovation() {
    const data = useStaticQuery(graphql`
        query innovationHero {
            file(relativePath: {eq: "heroes/innovation.jpg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <Layout>
            <div className={hero.smarthero}>
                <Img fluid={data.file.childImageSharp.fluid} className={hero.smartheroimage}/>
                <HeroHeadline h1="TSU SMART Technology Innovation Initiatives" sub="Immersive Teaching and Learning " />
            </div>
            <div className={styles.about}>
                <div className="container">
                    <div className="row align-items-start">
                        <Sectionheader name="About Initiatives" />
                        <div className="col-11 col-sm-8">
                            <p>The focus of SMART Technology is on exploring, researching, designing, and evaluating SMART Connected Mobile and Wearable Technologies to enhance teaching, learning, and the workforce. This involves investigating immersive learning through innovative Mixed Reality Technologies, incorporating virtual (VR), augmented (AR), and holographic (HR) simulations for replicating scenarios and teaching various skills.</p>
                            <div className={styles.buttonRow}>
                            <div><a role="button" className="btn btn-outline-primary" href="https://ai-tnstatesmartcenter.org ">Learn More</a></div>
                            <div><a role="button" className="btn btn-outline-primary" href="mailto:smartcenter@tnstate.edu">Contact Us</a></div>
                            </div>
                            <div className={styles.videobox}>
                                <div className="videocontainer">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jRQzl8ewDMQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <hr />
                                <div className="videocontainer">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/hrpytToK67E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <hr />
                                <div className="videocontainer">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/6AIYPQJoa_Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <hr />
                                <div className="videocontainer">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Jd2GK0qDtRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <hr />
                                <div className="videocontainer">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/XLP4YTpUpBI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <hr />
                                <div className="videocontainer">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/tnRJaHZH9lo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}
